// @@flow
// import { mockPayload } from '../../mock-data';
const UPDATE_PAIRS = '@@checkout/payment/update_pairs';

const initialState = {
  // FIXME
  // payload: __PROD__ ? undefined : mockPayload,
  // payload: mockPayload,
  status: undefined,
  paymentId: undefined,
  tier: undefined,
  totalAmount: undefined,
  isBig: undefined,
  isFirstTime: undefined,
  installmentOptions: undefined,
  selectedOption: undefined,
  configuration: undefined, // required configuration data
  patch: undefined, // patch data temp memo
  selectionOptionPhase: undefined,
  plusUpgradeWaitingTime: undefined,
  trackingId: undefined,
  onlySinglePayReason: undefined,
};

const payment = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAIRS: {
      return {
        ...state,
        ...action.pairs,
      };
    }
    default:
      return state;
  }
};

export const updatePaymentPairs = pairs => ({
  type: UPDATE_PAIRS,
  pairs,
});

export default payment;
