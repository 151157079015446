import UAParser from 'ua-parser-js';
import uuidv1 from 'uuid/v1';

const mixpanel = require('mixpanel-browser');

export const sessionId: string = uuidv1();

// Mixpanel tokens
// paidy-prod
const MIXPANEL_TOKEN_PROD = 'ed67017e77857eaee53344b82d3f4293';
// paidy-staging
const MIXPANEL_TOKEN_STAGING = '5b78e2538205d2e9599a651a3da11dc5';
// paidy-qa
const MIXPANEL_TOKEN_QA = 'ccb0c831119706a4c91c41ce82597a4b';

// Utils for naming tracking events
export const URL_TO_SCREEN: Object = {
  '/': 'Intro',
  '/otp': 'Authentication Payment Authorization',
  '/login': 'Login',
  '/high-ticket': 'Pending Intro',
  '/plus/regular': 'Plus Form (Regular)',
  '/plus/extended': 'Plus Form (Extended)',
  '/big': 'Big Form',
  '/big/single': 'Big Form (Single)',
  '/token/confirm': 'Token Registration',
  '/result/pending': 'Payment Pending',
  '/result/single-pay': 'Payment Success',
  '/result/multi-pay': 'Payment Success',
  '/result/token': 'Token Success',
  '/result/big': 'Payment Success',
  '/result/plus': 'Payment Success',
  '/result/digital': 'Payment Success',
  '/result/3pay': 'Payment Success',
  '/result/digital-3pay': 'Payment Success',
  '/error/payment-order-item-forbidden': 'Payment Failure (Not Allowed Item)',
  '/error/consumer-bill-statement-overdue': 'Payment Failure (Bill Overdue)',
  '/error/token-authentication-expired': 'Auth Failure (Expired Token)',
  '/result/rejected': 'Payment Failure (Generic)',
  '/result/token-rejected': 'Token Failure',
  '/help/login': 'Help Content (Login)',
  '/help/otp': 'Help Content (Authentication)',
  '/help/rejection': 'Help Content (Rejection)',
  '/help/all': 'Help Content (All)',
  '/help': 'Help Content',
  '/multi-pay/select': 'Multipay Installment Options',
  '/multi-pay/form': 'Multipay Form',
  '/multi-pay/confirm': 'Multipay Contract Preview',
  '/digital/form': 'Digital Form (Regular)',
  '/3pay/select': '3pay Selection',
  '/3pay/preselect': '3pay Selection',
  '/3pay/select/3pay-detail': '3pay Detail',
  '/3pay/confirm': '3pay Confirm',
  '/toc': 'T&C',
  '/error/consumer-phone-mismatch': 'Auth Failure (Phone Mismatch)',
  '/error/consumer-email-mismatch': 'Auth Failure (Email Mismatch)',
  '/error/request_content-malformed': 'Auth Failure (Request Failure)',
  '/error/request_entity-invalid': 'Auth Failure (Request Failure)',
  '/error/cors-malformed': 'Auth Failure (Request Failure)',
  '/error/cors-invalid': 'Auth Failure (Request Failure)',
  '/error/version-unknown': 'Auth Failure (Request Failure)',
  '/error/service-forbidden': 'Auth Failure (Internal Error)',
  '/error/service-conflict': 'Auth Failure (Internal Error)',
  '/error/service-exception': 'Auth Failure (Internal Error)',
  '/error/service-unavailable': 'Auth Failure (Internal Error)',
  '/error/service-error': 'Auth Failure (Internal Error)',
  // new error screens
  '/error/rejected.overdue-bill': 'Payment Failure (Bill Overdue)',
  '/error/rejected.exceeded-plus-limit': 'Payment Failure (Over Plus limit cannot retry)',
  '/error/rejected.exceeded-plus-limit-retriable': 'Payment Failure (Over Plus limit can retry)',
  '/error/rejected.merchant-requires-kyc': 'Payment Failure (eKYC required generic)',
  '/error/rejected.merchant-requires-kyc-hoshino': 'Payment Failure (eKYC required Hoshino)',
  '/error/rejected.generic': 'Payment Failure (Generic)',
  '/error/rejected.exceeded-nlp-limit-retriable': 'Payment Failure (Over NLP limit can retry)',
  '/error/rejected.exceeded-nlp-limit': 'Payment Failure (Over NLP limit cannot retry)',
  '/error/rejected.exceeded-nlp-limit-retriable-x1': 'Payment Failure (Over Plus limit can retry)',
  '/error/rejected.exceeded-nlp-limit-x1': 'Payment Failure (Over Plus limit cannot retry))',
  // plus upgrade
  '/error/rejected.hokatsu-rejected': 'Payment Failure Houkatsu rejected',
  '/error/plus-upgrade.timeout': 'Payment Timeout',
  '/error/plus-upgrade.outside-cic-hours': 'Payment Failure Outside CIC hour after KYC',
  '/error/plus-upgrade.kyc-rejected': 'Payment Failure KYC rejected',
};

export const URL_TO_EXTRAS: Object = {
  '/result/single-pay': { 'Event Type': 'Single' },
  '/result/multi-pay': { 'Event Type': 'Multipay' },
  '/result/big': { 'Event Type': 'Big' },
  '/result/plus': { 'Event Type': 'Plus' },
  '/result/digital': { 'Event Type': 'Digital' },
};

export const BAD_RESULT_TYPES_DISPLAY_NAMES: Object = {
  'consumer-bill-statement-overdue': 'Payment Failure (Overdue)',
  'payment-order-item-forbidden': 'Payment Failure (Not allowed item)',
  rejected: 'Payment Failure (Generic)',
};

export const FAQ_DISPLAY_NAMES: Object = {
  all: 'All',
  login: 'Login',
  otp: 'Authentication',
  multipay: 'Multipay',
  plus: 'Plus',
  digital: 'Digital',
  big: 'Big',
  token: 'Token',
  rejection: 'Rejection',
  threepay: '3Pay',
};

export const AUTH_TYPE_DISPLAY_NAMES: Object = {
  sms: 'SMS',
  voice: 'Voice',
  app: 'App',
};

export const LOGIN_DISPLAY_NAMES: Object = {
  email: 'Email',
  phone: 'Phone',
};

const getScreenName = (pathname: string): void => URL_TO_SCREEN[pathname];
const getScreenExtra = (pathname: string): void => URL_TO_EXTRAS[pathname];

// Action Names
export const MIXPANEL_ACTION_APP_OPEN: string = 'App Open';
export const MIXPANEL_ACTION_APP_CLOSE: string = 'App Close';
export const MIXPANEL_ACTION_BUTTON_CLICK: string = 'Button Click';
export const MIXPANEL_ACTION_CLICK: string = 'Click';
export const MIXPANEL_ACTION_PAGE_VIEW: string = 'Page View';
export const MIXPANEL_ACTION_SCROLL: string = 'Scroll';
export const MIXPANEL_ACTION_FIELD_EDIT: string = 'Field Edit';
export const MIXPANEL_ACTION_LINK_CLICK: string = 'Link Click';
export const MIXPANEL_ACTION_FORM_ERROR: string = 'Form Error';
export const MIXPANEL_ACTION_AUTHORIZATION_SUCCESS: string = 'Authentication Success';
export const MIXPANEL_ACTION_AUTHORIZATION_FAILURE: string = 'Authentication Failure';

export const MIXPANEL_ACTION_API_ERROR: string = 'API Error';
export const MIXPANEL_ACTION_PRE_CALLBACK: string = 'Pre Callback';
export const MIXPANEL_ACTION_CALLBACK: string = 'Callback';

// Common Actions
export const MIXPANEL_ACTION_SUBMIT: string = 'MIXPANEL_ACTION_SUBMIT';
export const MIXPANEL_ACTION_BACK: string = 'MIXPANEL_ACTION_BACK';
export const MIXPANEL_ACTION_INSTALLMENT_SELECT: string = 'MIXPANEL_ACTION_INSTALLMENT_SELECT';
export const MIXPANEL_ACTION_NEXT: string = 'MIXPANEL_ACTION_NEXT';
export const MIXPANEL_ACTION_CONFIRM: string = 'MIXPANEL_ACTION_CONFIRM';
export const MIXPANEL_ACTION_REMEMBER_ME: string = 'MIXPANEL_ACTION_REMEMBER_ME';
export const MIXPANEL_ACTION_HELP: string = 'MIXPANEL_ACTION_HELP';

// Action Items
export const MIXPANEL_ITEM_SUBMIT = 'Submit';
export const MIXPANEL_ITEM_BACK = 'Back';
export const MIXPANEL_ITEM_INSTALLMENT_OPTION = 'Installment Option';
export const MIXPANEL_ITEM_NEXT = 'Next';
export const MIXPANEL_ITEM_CONFIRM = 'Confirm';
export const MIXPANEL_ITEM_REMEMBER_ME = 'Remember Me';
export const MIXPANEL_ITEM_HELP = 'Help Content';


// Shortcut for some common events
export const COMMON_ACTION_SETS = {
  [MIXPANEL_ACTION_SUBMIT]: {
    actionName: MIXPANEL_ACTION_BUTTON_CLICK,
    extra: {
      item: MIXPANEL_ITEM_SUBMIT,
    },
  },
  [MIXPANEL_ACTION_BACK]: {
    actionName: MIXPANEL_ACTION_BUTTON_CLICK,
    extra: {
      item: MIXPANEL_ITEM_BACK,
    },
  },
  [MIXPANEL_ACTION_INSTALLMENT_SELECT]: {
    actionName: MIXPANEL_ACTION_BUTTON_CLICK,
    extra: {
      item: MIXPANEL_ITEM_INSTALLMENT_OPTION,
    },
  },
  [MIXPANEL_ACTION_NEXT]: {
    actionName: MIXPANEL_ACTION_BUTTON_CLICK,
    extra: {
      item: MIXPANEL_ITEM_NEXT,
    },
  },
  [MIXPANEL_ACTION_CONFIRM]: {
    actionName: MIXPANEL_ACTION_BUTTON_CLICK,
    extra: {
      item: MIXPANEL_ITEM_CONFIRM,
    },
  },
  [MIXPANEL_ACTION_REMEMBER_ME]: {
    actionName: MIXPANEL_ACTION_BUTTON_CLICK,
    extra: {
      item: MIXPANEL_ITEM_REMEMBER_ME,
    },
  },
  [MIXPANEL_ACTION_HELP]: {
    actionName: MIXPANEL_ACTION_BUTTON_CLICK,
    extra: {
      item: MIXPANEL_ITEM_HELP,
    },
  },
};

function checkBrowserCompatibility(target, name, descriptor) {
  const isIE9 =
    ((!!window.ActiveXObject && +/msie\s(\d+)/i.exec(navigator.userAgent)[1]) || NaN) === 9;

  // if the browser is IE9, do not call the mixpanel function
  if (isIE9) {
    // eslint-disable-next-line
    descriptor.value = () => {};
  }
}

export class MixpanelHelpers {
  // Initialize mixpanel
  @checkBrowserCompatibility
  static initialize() {
    const checkoutEnv = process.env.REACT_APP_ENVIRONMENT;
    let mixpanelToken = MIXPANEL_TOKEN_PROD;

    switch (true) {
      case checkoutEnv.indexOf('development') > -1: {
        mixpanelToken = MIXPANEL_TOKEN_QA;

        break;
      }
      case checkoutEnv.indexOf('staging') > -1: {
        mixpanelToken = MIXPANEL_TOKEN_STAGING;

        break;
      }
      case checkoutEnv.indexOf('production') > -1: {
        mixpanelToken = MIXPANEL_TOKEN_PROD;

        break;
      }
      default:
        break;
    }

    mixpanel.init(mixpanelToken, {
      // Enable debug mode when not in production
      loaded(mp) {
        if (checkoutEnv.indexOf('production') < 0) {
          console.log('Paidy Checkout: Mixpanel is in debug mode.');
          mp.set_config({ debug: true });
        }
      },
    });
    mixpanel.reset();
    mixpanel.register({
      Product: 'Checkout',
      Platform: 'Web',
    });
  }

  // This is to remove any super properties that we now send as event properties
  // Mixpanel cookies are set to expire in 365 days by default. So this method can be
  // removed in a year when all the super properties below have expired
  @checkBrowserCompatibility
  static unregisterSuperProperties(properties = []) {
    properties.forEach(property => {
      mixpanel.unregister(property);
    });
  }

  // Mixpanel functions to set super properties, event properties that are set once to automatically
  // attach to every event tracked (these should only be called once). They are stored in cookies.
  @checkBrowserCompatibility
  static registerReturningUserStatus(returningUserStatus: boolean): void {
    const status = returningUserStatus ? 'TRUE' : 'FALSE';

    mixpanel.register({
      'Returning User': status,
    });
  }

  @checkBrowserCompatibility
  static registerRememberMeStatus(rememberMeStatus: boolean): void {
    const status = rememberMeStatus ? 'TRUE' : 'FALSE';

    mixpanel.register({
      'Is User Remembered': status,
    });
  }

  // Methods to set session data to send as event properties to Mixpanel. These are not stored in cookies.
  static addSessionData(data = {}) {
    MixpanelHelpers.sessionData = {
      ...MixpanelHelpers.sessionData,
      ...data,
      'Session Id': sessionId,
    };
  }

  @checkBrowserCompatibility
  static registerOrderAmount(orderAmount) {
    MixpanelHelpers.addSessionData({
      'Order Amount': orderAmount,
    });
  }

  @checkBrowserCompatibility
  static registerStoreName(storeName: string): void {
    MixpanelHelpers.addSessionData({
      'Store Name': storeName,
    });
  }

  @checkBrowserCompatibility
  static registerUserAgent(): void {
    if (typeof navigator !== 'undefined' && 'userAgent' in navigator) {
      const userAgent = navigator.userAgent || '';
      const parsedDevice = UAParser(userAgent).device;

      MixpanelHelpers.addSessionData({
        'User Agent': userAgent,
        'Device Vendor': parsedDevice.vendor,
        'Device Model': parsedDevice.model,
        'Device Type': parsedDevice.type,
      });
    }
  }

  @checkBrowserCompatibility
  static registerMerchantTracking(merchantTracking: string): void {
    MixpanelHelpers.addSessionData({
      'Merchant Tracking': merchantTracking,
    });
  }

  @checkBrowserCompatibility
  static registerMerchantConfigTier(tier: string): void {
    MixpanelHelpers.addSessionData({
      'Merchant Config Tier': tier,
    });
  }

  @checkBrowserCompatibility
  static registerMerchantConfig(config: Object): void {
    MixpanelHelpers.addSessionData({
      'Merchant Config': JSON.stringify(config),
    });
  }

  @checkBrowserCompatibility
  static registerMerchantPayload(payload: Object): void {
    // to avoid sending personal information to mixpanel, we
    // are redacting buyer info and shipping address
    const filteredPayload = payload.shipping_address
      ? {
          ...payload,
          buyer: 'redacted',
          shipping_address: 'redacted',
        }
      : {
          ...payload,
          buyer: 'redacted',
        };

    MixpanelHelpers.addSessionData({
      'Merchant Payload': filteredPayload,
    });
  }

  @checkBrowserCompatibility
  static registerViewportOrientation(): void {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const viewPortAspectRatio = Math.floor((viewportWidth / viewportHeight) * 100) / 100;
    const viewportOrientation = viewportHeight > viewportWidth ? 'portrait' : 'landscape';

    MixpanelHelpers.addSessionData({
      'Viewport Orientation': viewportOrientation,
      'Viewport Width': viewportWidth,
      'Viewport Height': viewportHeight,
      'Viewport AspectRatio': viewPortAspectRatio,
    });
  }

  /**
   * Mixpanel functions to set user profiles (https://help.mixpanel.com/hc/en-us/articles/115004497803)
   * A randomly Mixpanel-generated user id is assigned to an anonymous user when the profile is created,
   * When the anonymous user entered credentials, we call mixpanel.identify() with the entered trackingId.
   * This maps the trackingId to the original Mixpanel unique id
   */
  @checkBrowserCompatibility
  static userProfileIdentify(trackingId: string): void {
    mixpanel.identify(trackingId);
    mixpanel.people.set({ 'Consumer Hash': trackingId });
  }

  @checkBrowserCompatibility
  static userProfileUpdate(userInformation: Object): void {
    mixpanel.people.set({ ...userInformation });
  }

  @checkBrowserCompatibility
  static recordRevenue(orderAmount) {
    mixpanel.people.track_charge(orderAmount, { $time: new Date() });
  }

  // Helper functions for tracking events and their durations
  @checkBrowserCompatibility
  static trackAction({ pathname, customPath, actionName, actionItem, extraData }) {
    const location = customPath || getScreenName(pathname) || pathname;
    const screenExtra = customPath ? null : getScreenExtra(pathname) || null;
    const { actionName: commonActionName, extra: commonExtra } =
      COMMON_ACTION_SETS[actionName] || {};
    const eventName = `${location} - ${commonActionName || actionName}`;
    const item = actionItem ? { item: actionItem } : null;

    // To make showing the events in the correct order easier we add a timestamp prop
    // https://community.mixpanel.com/ideas/be-able-to-capture-milliseconds-on-events-3276
    const timeStamp = {
      'Time MS': Date.now(),
    };

    mixpanel.track(eventName, {
      ...commonExtra,
      ...screenExtra,
      ...extraData,
      ...item,
      ...MixpanelHelpers.sessionData,
      ...timeStamp,
    });
  }

  @checkBrowserCompatibility
  static trackDuration({
    pathname,
    customPath,
    actionName,
    actionItem,
    extraData,
    shouldEndTracker = false,
  }) {
    const location = customPath || getScreenName(pathname) || pathname;
    const screenExtra = customPath ? null : getScreenExtra(pathname) || null;
    const { actionName: commonActionName, extra: commonExtra } =
      COMMON_ACTION_SETS[actionName] || {};
    const eventName = `${location} - ${commonActionName || actionName} - Duration`;
    const item = actionItem ? { item: actionItem } : null;

    // To make showing the events in the correct order easier we add a timestamp prop
    // https://community.mixpanel.com/ideas/be-able-to-capture-milliseconds-on-events-3276
    const timeStamp = {
      'Time MS': Date.now(),
    };

    if (shouldEndTracker) {
      mixpanel.track(eventName, {
        ...commonExtra,
        ...screenExtra,
        ...extraData,
        ...item,
        ...MixpanelHelpers.sessionData,
        ...timeStamp,
      });
    } else {
      mixpanel.time_event(eventName);
    }
  }
}
