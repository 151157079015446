// @flow
/* eslint-disable camelcase */
import React, { useCallback } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import ThreePaySelect, { OnlySinglePayReasonType } from './ThreePaySelect';
import ThreePayConfirm from './ThreePayConfirm';
import PlusUpgradePreselect from '../PlusUpgradePreselect/PlusUpgradePreselect';

import { updatePaymentPairs } from '../../redux/modules/payment';

import { MixpanelHelpers, MIXPANEL_ACTION_CLICK } from '../../utils/mixpanel';
import type { ThreePayOption, InstallmentPlanOptions, UnavailableOption } from './type';
import { paymentStatusMap, SELECTION_OPTION_PHASE } from '../../constants';

const getSortedOptions = options => {
  return options.sort((opt1, opt2) => {
    if (opt1.kind === '12pay') {
      return -1;
    } else if (opt2.kind === '12pay') {
      return 1;
    } else if (opt1.kind === '6pay') {
      return -1;
    } else if (opt2.kind === '6pay') {
      return 1;
    } else if (opt1.kind === '3pay') {
      return -1;
    } else if (opt2.kind === '3pay') {
      return 1;
    }

    return 0;
  });
};

type Props = {
  // meta
  onPatch: Function,
};

const ThreePay = ({ onPatch }: Props) => {
  const installmentPlanOptions: InstallmentPlanOptions = useSelector(
    state => state.payment.installmentPlanOptions
  );

  const trackingId = useSelector(state => state.payment.trackingId);
  const paymentStatus = useSelector(state => state.payment.status);
  const selectionOptionPhase = useSelector(state => state.payment.selectionOptionPhase);
  const isRequiredPlusUpgrade = useSelector(state => state.merchant.isRequiredPlusUpgrade) || false;

  const availableOptions: ThreePayOption[] = installmentPlanOptions.available;
  const unavailableOptions: UnavailableOption[] = installmentPlanOptions.unavailable;

  const sortedAvailableOptions = getSortedOptions(availableOptions);
  const sortedUnavailableOptions = getSortedOptions(unavailableOptions);

  const selectedOption: ThreePayOption =
    useSelector(state => state.payment.selectedOption) ||
    (selectionOptionPhase !== SELECTION_OPTION_PHASE.UNAVAILABLE
      ? sortedAvailableOptions[0]
      : undefined);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch('/3pay/:phase?');

  const { pathname } = location;

  const setSelectedOption = (option: ThreePayOption) => {
    dispatch(updatePaymentPairs({ selectedOption: option }));

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_CLICK,
      extraData: {
        kind: option.kind,
        item: 'Installment Option',
      },
    });
  };

  const onClickNext = useCallback(
    (option: ThreePayOption, onlySinglePayReason: OnlySinglePayReasonType) => {
      dispatch(updatePaymentPairs({ selectedOption: option, onlySinglePayReason }));

      let actionItem =
        paymentStatus === paymentStatusMap.PENDING
          ? 'Start plus upgrade'
          : isRequiredPlusUpgrade &&
            selectionOptionPhase &&
            selectionOptionPhase === SELECTION_OPTION_PHASE.UNAVAILABLE
          ? 'Select'
          : 'Next';

      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_CLICK,
        actionItem,
        extraData: {
          kind: option.kind,
        },
      });

      if (paymentStatus === paymentStatusMap.PENDING) {
        let webEKYCURL =
          process.env.REACT_APP_ENVIRONMENT === 'production'
            ? 'https://my.paidy.com/ekyc'
            : 'https://my.paidy-staging.com/ekyc';
        let to =
          `${webEKYCURL}?partnerReferenceId=paidy-checkout` +
          (trackingId ? `&tracking=${trackingId}` : '');
        window.open(to, '_blank');
        return history.push('/plus-upgrade/pending');
      }

      history.push('/3pay/confirm');
    }
  );

  switch (match.params.phase) {
    case 'select': {
      return (
        <ThreePaySelect
          selectedOption={selectedOption}
          onSelectOption={setSelectedOption}
          availableOptions={sortedAvailableOptions}
          unavailableOptions={sortedUnavailableOptions}
          onClickNext={onClickNext}
        />
      );
    }
    case 'preselect': {
      return (
        <PlusUpgradePreselect
          selectedOption={selectedOption}
          onSelectOption={setSelectedOption}
          availableOptions={sortedAvailableOptions}
          unavailableOptions={sortedUnavailableOptions}
          onClickNext={onClickNext}
        />
      );
    }
    case 'confirm': {
      return (
        <ThreePayConfirm
          onPatch={onPatch}
          selectedOption={selectedOption}
          options={sortedAvailableOptions}
          unavailableOptions={sortedUnavailableOptions}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default ThreePay;
