/* eslint-disable camelcase */
// @flow
import axios from 'axios';
import idx from 'idx';
import autobind from 'autobind-decorator';
import { paymentPayloadType } from './types';
import { getFormattedZip } from './utils';

import { sessionId, MixpanelHelpers, MIXPANEL_ACTION_API_ERROR } from './utils/mixpanel';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';

const defaultHeader: Object = {
  'Paidy-Version': '2016-07-01',
  'Session-Id': sessionId,
};

const errHandler = (err: Object) => {
  if (err) {
    const response = err.response;
    const data = response ? response.data : null;

    if (data && data.code) {
      MixpanelHelpers.trackAction({
        customPath: 'Checkout',
        actionName: MIXPANEL_ACTION_API_ERROR,
        extraData: {
          'Error Code': data.code,
          Reference: data.reference,
        },
      });
    } else {
      // Add error information that doesn't have a data nd response code
      MixpanelHelpers.trackAction({
        customPath: 'Checkout',
        actionName: MIXPANEL_ACTION_API_ERROR,
        extraData: {
          'Error Code': err.message,
          Reference: err.name,
        },
      });
    }
  }

  return Promise.reject(err.response);
};

export default class PaymentAPI {
  id: ?string = '';
  token: ?Object;
  logoURL: ?string = '';
  tier: ?string = '';

  // this relate directly to App.js' postMessage communication
  constructor(
    apiKey: string,
    apiHost: string = 'https://apis.paidy-staging.com/merchants',
    tier: ?string,
    token: ?Object,
    logoURL: ?string
  ) {
    if (!apiKey) {
      throw new Error('API Key is undefined.');
    }

    axios.defaults.headers.common.Authorization = `Bearer ${apiKey}`;
    axios.defaults.baseURL = apiHost;

    this.token = token;
    this.logoURL = logoURL;
    this.tier = tier;
  }

  create(payload: paymentPayloadType) {
    const _payload = { ...payload };
    let requestURL = '/checkout';

    // token payment
    if (_payload.kind === 'recurring') {
      requestURL = '/tokens';

      const originalZip = idx(payload, _ => _.origin.address.zip);

      if (originalZip) {
        _payload.origin.address.zip = getFormattedZip(originalZip);
      }
    }

    if (_payload.tier === 'digital') {
      _payload.tier = undefined;
    }

    return axios
      .post(requestURL, _payload, {
        headers: defaultHeader,
      })
      .then(this.setId)
      .catch(errHandler);
  }

  @autobind
  setId(res: any) {
    this.id = idx(res, _ => _.data.id);

    return res;
  }

  authenticate(pinCode: string, ineligibleForUpgrade: ?boolean): any {
    if (!this.id) {
      return Promise.reject('Payment id is not available.');
    }

    const paymentType = this.token ? 'tokens' : 'checkout';
    const checkoutPayload = {
      auth_code: pinCode,
      ...(ineligibleForUpgrade ? { ineligible_for_upgrade: 'environment_incompatible' } : {}),
    };

    const tokenPayload = {
      auth_pin: pinCode,
      wallet_id: (this.token && this.token.wallet_id) || 'default',
    };

    return axios
      .post(
        `/${paymentType}/${this.id}/authenticate`,
        this.token ? tokenPayload : checkoutPayload,
        {
          headers: defaultHeader,
        }
      )
      .then(data => data)
      .catch(errHandler);
  }

  confirmToken() {
    if (!this.id) {
      return Promise.reject('Payment id is not available.');
    }

    const payload: {
      terms_and_conditions: Object,
      wallet_id: string,
    } = {
      terms_and_conditions: {
        id: 'v1',
        // TOFIX: this is to align with 1.0 but we don't need this anymore in 2.0
        // should communicate with Platform team and remove this in the future
        url: 'https://checkout.uat.paidy.io/legal/terms_one_shot.html',
      },
      wallet_id: (this.token && this.token.wallet_id) || 'default',
    };

    return (
      // $FlowFixMe
      axios
        .post(`/tokens/${this.id}/confirm`, payload, {
          headers: defaultHeader,
        })
        .then(data => data)
        .catch(errHandler)
        // TOFIX: Always resolve for correct error handling
        // But two catch side by side is a bad smell
        .catch(() => {})
    );
  }

  patchToken({ consumer_data }: { consumer_data: Object }) {
    if (!this.id) {
      return Promise.reject(new Error('Payment ID is undefined.'));
    }

    if (!consumer_data) {
      return Promise.reject(new Error('Consumer data is required for token patch.'));
    }

    // $FlowFixMe
    return axios
      .patch(
        `/tokens/${this.id}`,
        {
          consumer_data,
        },
        { headers: defaultHeader }
      )
      .then(data => data)
      .catch(errHandler);
  }

  patch({
    installment_plan_kind,
    number_of_installments,
    contract_data,
    plus_contract_data,
    big_contract_data,
    consumer_data,
    ineligibleForUpgrade,
  }: {
    installment_plan_kind: ?String,
    number_of_installments: ?number,
    contract_data: ?Object,
    plus_contract_data: ?Object,
    big_contract_data: ?Object,
    consumer_data: ?Object,
    ineligibleForUpgrade: ?boolean,
  }): any {
    if (!this.id) {
      return Promise.reject(new Error('Payment ID is undefined.'));
    }

    const payload = {};

    if (installment_plan_kind) {
      payload.installment_plan_kind = installment_plan_kind;
    }

    if (number_of_installments) {
      payload.number_of_installments = number_of_installments;
    }

    if (contract_data) {
      payload.contract_data = contract_data;
    }

    if (plus_contract_data) {
      payload.plus_contract_data = plus_contract_data;
    }

    if (big_contract_data) {
      payload.big_contract_data = big_contract_data;
    }

    if (consumer_data) {
      payload.consumer_data = consumer_data;
    }

    if (ineligibleForUpgrade) {
      payload.ineligible_for_upgrade = 'environment_incompatible';
    }

    return axios
      .patch(`/checkout/${this.id}`, payload, { headers: defaultHeader })
      .then(data => data)
      .catch(errHandler);
  }

  challenge({
    auth_challenge_dispatch_method,
  }: {
    auth_challenge_dispatch_method: 'sms' | 'voice',
  }): any {
    const isToken = !!this.token && this.token.type === 'recurring';
    let voiceAuthPayload = { auth_challenge_dispatch_method };

    if (isToken) {
      voiceAuthPayload = {
        activation_method: 'pin',
        activation_dispatch_method: 'voice',
        ...(this.token.wallet_id ? { wallet_id: this.token.wallet_id } : {}),
      };
    }

    if (this.id) {
      return axios
        .post(`/${isToken ? 'tokens' : 'checkout'}/${this.id}/challenge`, voiceAuthPayload, {
          headers: defaultHeader,
        })
        .then(data => data)
        .catch(errHandler);
    }

    return Promise.reject(new Error('Something went wrong.'));
  }

  fetchStatus() {
    if (!this.id) {
      return Promise.reject(new Error('Payment ID is undefined.'));
    }

    if (this.id) {
      return axios
        .get(`/checkout/${this.id}`, { headers: defaultHeader })
        .then(data => data)
        .catch(errHandler);
    }

    return Promise.reject(new Error('Something went wrong.'));
  }
}
